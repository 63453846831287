///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* List */

ol {
	list-style: decimal;
	margin: 0 0 _size(element-margin) 0;
	padding-left: 1.25em;

	li {
		padding-left: 0.25em;
	}
}

ul {
	list-style: disc;
	margin: 0 0 _size(element-margin) 0;
	padding-left: 1em;

	li {
		padding-left: 0.5em;
	}

	&.alt {
		list-style: none;
		padding-left: 0;

		li {
			border-top: solid 1px;
			padding: 0.5em 0;

			&:first-child {
				border-top: 0;
				padding-top: 0;
			}
		}
	}
}

dl {
	margin: 0 0 _size(element-margin) 0;

	dt {
		display: block;
		font-weight: _font(weight-bold);
		margin: 0 0 (_size(element-margin) * 0.5) 0;
	}

	dd {
		margin-left: _size(element-margin);
	}

	&.alt {
		dt {
			display: block;
			width: 3em;
			margin: 0;
			clear: left;
			float: left;
		}

		dd {
			margin: 0 0 0.85em 5.5em;
		}

		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}
}

@mixin color-list($p: null) {
	ul {
		&.alt {
			li {
				border-top-color: _palette($p, border);
			}
		}
	}

	dl {
		dt {
			color: _palette($p, fg-bold);
		}
	}
}

@include color-list;
