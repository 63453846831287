///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Box */

.box {
	border-radius: _size(border-radius);
	border: solid _size(border-width);
	margin-bottom: _size(element-margin);
	padding: 1.5em;

	> :last-child,
	> :last-child > :last-child,
	> :last-child > :last-child > :last-child {
		margin-bottom: 0;
	}

	&.alt {
		border: 0;
		border-radius: 0;
		padding: 0;
	}
}

@mixin color-box($p: null) {
	.box {
		border-color: _palette($p, border);
	}
}

@include color-box;
