///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Basic */

// MSIE: Required for IEMobile.
@-ms-viewport {
	width: device-width;
}

// MSIE: Prevents scrollbar from overlapping content.
body {
	-ms-overflow-style: scrollbar;
}

// Ensures page width is always >=320px.
@include breakpoint('<=xsmall') {
	html,
	body {
		min-width: 320px;
	}
}

// Set box model to border-box.
// Based on css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}
body {
	background-color: _palette(bg);
	@include vendor('background-image', ('url("../css/images/overlay.png")', 'linear-gradient(45deg, #{_palette(bg1)} 15%, #{_palette(bg2) 85%})'));

	// Stops initial animations until page loads.
	&.is-preload {
		*,
		*:before,
		*:after {
			@include vendor('animation', 'none !important');
			@include vendor('transition', 'none !important');
		}
	}
}
